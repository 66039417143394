


















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

// components
import ExerciseModal from '@/components/modals/exercise/ExerciseModal.vue'
import BankExercisesTable from '@/components/tables/BankExercisesTable.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// types
import { ExerciseShortResource } from '@/store/types'

@Component({
  components: {
    BankExercisesTable,
    ExerciseModal,
  },
})
export default class BankList extends Mixins(NotifyMixin, PermissionsMixin) {
  private showExerciseModal = false
  private selectedExerciseUUID = ''

  private handleSelectExercise (exercise: ExerciseShortResource) {
    this.selectedExerciseUUID = exercise.uuid
    this.showExerciseModal = true
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Банк домашних заданий',
    }
  }
}
