
















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { ExerciseCloneToManyData, ExerciseShortResource, NameValueResource } from '@/store/types'

@Component({
  components: {
    Autocomplete,
    ButtonArrow,
    DateTimeInput,
    Dialog,
    ModalWrapper,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DuplicateExerciseModal extends NotifyMixin {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private selectedExercise!: ExerciseShortResource | null

  private get selectedCoursesTitle() {
    const selected = this.form.courseIds.map(id => ({
      name: this.coursesObject[id],
      value: id,
    }))
    return {
      first: selected.slice(0, 5),
      more: selected.slice(5),
    }
  }

  // ИД последнего запрошенного предмета
  // нужен, чтобы не запрашивать курсы повторно для того же предмета при открытии модалки
  private currentSubjectID: number | null = null
  private courses: NameValueResource[] = []
  private coursesObject: Record<number, string> = {}
  private isShowMore = false

  // Можно ли ставить дедлайн раньше текущего времени или даты выдачи
  private isDeadlineEarlierNow = process.env.VUE_APP_IS_DEADLINE_EARLIER_NOW === '1'

  private form: ExerciseCloneToManyData = {
    courseIds: [],
    deadlineAt: '',
    spendingAt: '',
  }

  private fetchCourses() {
    ManagerExercisesModule.fetchCourses({
      showHiddenAt: false,
      subjectId: this.selectedExercise?.subject.value as number || undefined,
    })
      .then(response => {
        this.courses = response
        this.coursesObject = {}
        response.forEach(item => this.coursesObject[item.value as number] = item.name)
        this.currentSubjectID = this.selectedExercise?.subject.value as number
      })
  }

  private deleteChip(course: any) {
    this.form.courseIds = this.form.courseIds.filter(id => id !== course)
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private cloneExercise() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerExercisesModule.duplicateExercise({
            body: this.form,
            exerciseUuid: this.selectedExercise?.uuid as string,
          })
            .then(() => {
              this.handleClose()
              this.notifySuccess('Домашнее задание добавлено в курсы.')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      if (!this.currentSubjectID || this.currentSubjectID !== this.selectedExercise?.subject.value as number) {
        this.fetchCourses()
      }
    } else {
      this.isShowMore = false
      requestAnimationFrame(() => ((this.$refs as any).form.reset()))
      this.form = {
        courseIds: [],
        deadlineAt: '',
        spendingAt: '',
      }
    }
  }
}
