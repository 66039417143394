









































































































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import DateInput from '@/components/_uikit/controls/DateInput.vue'
import DuplicateExerciseModal from '@/components/modals/exercise/DuplicateExerciseModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { ExerciseAccessLevel, ExerciseShortResource, ExercisesFilter, ExercisesFilterType } from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    DateInput,
    DuplicateExerciseModal,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class BankExercisesTable extends Mixins(NotifyMixin, PermissionsMixin) {
  private footerOptions = tableFooterOptions
  private hoveredRowIndex = -1

  private get filter () {
    return ManagerExercisesModule.exercisesFilter
  }

  private set filter (filter: ExercisesFilter) {
    if (!isEqual(filter, this.filter) || this.exercises.length === 0) {
      ManagerExercisesModule.setExercisesFilter(filter)
      this.fetchExercises()
    }
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get accessLevels () {
    return DictionaryModule.exerciseAccessLevels
  }

  private get taskTypes () {
    return DictionaryModule.taskTypes
  }

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { sortable: false, text: 'Задание', value: 'taskType' },
      { sortable: false, text: 'Название', value: 'task' },
      { sortable: false, text: 'Предмет', value: 'subject.name' },
      { sortable: false, text: 'Тип', value: 'accessLevel' },
      { sortable: false, text: 'Автопроверка', value: 'autoCheck' },
      { sortable: false, text: '', value: 'actions' },
    ]
  }

  private get exercises () {
    return ManagerExercisesModule.exercises.data
  }

  private get pagination () {
    return ManagerExercisesModule.exercises.meta
  }

  private get onlyPersonalExerciseAvailable() {
    return this.hasPermission(this.Permission.BANK_PERSONAL_VIEW) &&
      !this.hasPermission(this.Permission.BANK_BASE_VIEW)
  }

  private showDuplicateModal = false
  private selectedExercise: ExerciseShortResource | null = null

  private mounted() {
    if (this.onlyPersonalExerciseAvailable) {
      this.filter.accessLevel = ExerciseAccessLevel.PERSONAL
    }
  }

  @Bind
  @Debounce(300)
  private fetchExercises () {
    ManagerExercisesModule.fetchExercises()
      .catch(this.notifyError)
  }

  private handleFilter (field: ExercisesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.exercises.findIndex(_item => _item.id === id)
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.exercises.findIndex(_item => _item.id === id)
  }

  private handleHoverReset() {
    this.hoveredRowIndex = -1
  }

  private handleRowClick (exercise: ExerciseShortResource) {
    this.$emit('select', exercise)
  }

  private handleEditTask (exercise: ExerciseShortResource) {
    this.$router.push({
      name: 'manager.bank.exercises.item.edit',
      params: { exerciseUUID: exercise.uuid },
    })
  }

  private handleDuplicate(exercise: ExerciseShortResource) {
    this.showDuplicateModal = true
    this.selectedExercise = exercise
  }

  private handleCopyToBank(exercise: ExerciseShortResource) {
    ManagerExercisesModule.duplicateExerciseToBank(exercise.uuid)
      .then(response => {
        this.$router.push({
          name: 'manager.bank.exercises.item.edit',
          params: { exerciseUUID: response.uuid },
        })
      })
      .catch(this.notifyError)
  }

  private handleClick(event: MouseEvent, e: any) {
    e.click(event)
  }
}
